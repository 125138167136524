<div class="btn-cart">
    <div class="item" (click)="toCart()">
        <div class="icon">
            <i class="fa-solid fa-cart-shopping"></i>  
        </div>
        <div class="text">
            <span>Meu <br>Carrinho</span>
        </div>
    </div> 
    <!--<a routerLink="carrinho" title="Carrinho de Compras" class="btn-header-action"> 
        <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/carrinho.svg" width="25px" height="25px" alt="Carrinho" />    
        <div class="text">  
            <span>Meu</span><br>
            <strong>Carrinho ({{qtd}})</strong>
        </div>
    </a>-->    
</div>
