<div class="menu-partial {{classCustom}} template-{{template}}">
    <button type="button" class="btn-open" (click)="_open()">
        <i class="fa-solid fa-bars"></i>
    </button>  
    <div class="close" #close (click)="_open()">  
        <i class="fa-solid fa-xmark"></i>
    </div>    
    <div class="content" #content> 
        <ul>
            <li>
                <a routerLink="" title="Home">
                    <span>Home</span>
                </a>    
            </li>
            <li>
                <a routerLink="/sobre" title="Sobre Nós">
                    <span>Sobre Nós</span>
                </a>    
            </li>
            <li> 
                <a routerLink="/servicos" title="Nossos Serviços">
                    <span>Passeios</span>
                </a>    
            </li>
            <li>
                <a routerLink="/destino" title="Sobre o Destino">
                    <span>Sobre o Destino</span>  
                </a>    
            </li>
            <li>
                <a routerLink="/blog" title="Nosso Blog">
                    <span>Nosso Blog</span>
                </a>    
            </li>
            <li>
                <a routerLink="/contato" title="Contato">
                    <span>Contato</span>
                </a>    
            </li>
        </ul> 
    </div>   
</div> 
<div class="overlay" #overlay></div>   

     
