<div class="product-item {{classCustom}}" itemscope itemtype="https://schema.org/Product">
  <div class="capa" *ngIf="produto.foto != null" (click)="_click()">  
    <img itemprop="url" width="300px" height="200px" defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]="produto.foto" itemprop="image" [alt]="produto.nome" width="100%" /> 
  </div>
  <div class="capa" *ngIf="produto.foto == null" (click)="_click()">
    <img itemprop="url" width="300px" height="200px" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" width="100%" />
  </div> 
  <div class="content">   
      <h4 itemprop="name">{{produto.nome}}</h4> 
      <article *ngIf="produto.min_descricao != null" [innerHtml]="produto.min_descricao | safeHtml"></article>
      <div class="info">
          <div class="item" *ngIf="produto.duracao != null">
              <div class="icon">
                  <i class="fa-solid fa-clock"></i>
              </div> 
              <span>Duração Aprox: {{produto.duracao | formatDuracao}}</span>  
          </div>
          <div class="item" *ngIf="produto.dias.length > 0">
              <div class="icon">
                  <i class="fa-solid fa-calendar-day"></i>
              </div>
              <span>{{produto.dias | formatDias}}</span>
          </div>     
      </div>   
      <div class="linha"></div>
      <div class="footer">
          <div class="box"> 
              <a href="javascript:void();" alt="Ver produto" class="link-product" (click)="_click()">
                  <span class="text">Detalhes e Reservar</span>
                  <span class="installments" *ngIf="produto.mostrar_parcelas">{{produto.parcelas}}x sem juros</span>
              </a>    
          </div>
          <div class="box">  
            <div class="tariff" *ngIf="!produto.promocao">
                <span class="a-partir">a partir de</span>
                <strong class="value" *ngIf="produto.menor_tarifa > 0">
                  <span itemprop="price" class="cifrao" itemprop="priceCurrency">R$ </span> 
                  {{produto.menor_tarifa | currency: ' '}}  
                </strong> 
                <strong class="value" *ngIf="produto.menor_tarifa == 0">
                  CONSULTE!
                </strong>
            </div>
            <div class="tariff tariff-promocao" *ngIf="produto.promocao">
              <div class="valor-promocao"> 
                <span>De <strong>R$ {{produto.menor_tarifa | currency: ' '}}</strong></span>
              </div>
              <strong class="value">
                <span>Por </span>
                <span class="cifrao" itemprop="priceCurrency">R$ </span> 
                <span itemprop="price">{{produto.promocao_valor | currency: ' '}}</span> 
              </strong>
            </div>
          </div>   
      </div>    
  </div>  
  <span style="display:none;" itemprop="sku">{{produto.id}}</span>
</div>
  