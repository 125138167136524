<div [ngSwitch]="template">
    <div *ngSwitchCase="null" class="module-newsletter" [ngStyle]="{'background-image': 'url(images/newsletter/bg.jpg)'}">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-center">
                    <h3>{{data.subtitulo}}</h3>
                </div>
                <div class="col-md-8">  
                    <form class="form-custom" (submit)="send()" >
                        <div class="item item-header">
                            <h4>{{data.titulo}}</h4>
                            <span>Se cadastre aqui :)</span>
                        </div>    
                        <div class="item item-input">
                            <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu Nome" />
                        </div>    
                        <div class="item item-input">
                            <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu email" />    
                        </div> 
                        <div class="item item-input">
                            <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Seu WhatsApp" mask="(99) 99999-9999" /> 
                        </div>    
                        <div class="item item-button">
                            <button> 
                                <i class="fa-solid fa-user"></i>
                                <span>Me cadastrar</span>
                            </button>
                        </div>    
                    </form> 
                </div>
            </div>   
        </div>
    </div>
    <div class="bg"></div>
    </div>
    <div *ngSwitchCase="'blog'" class="template-blog">
        <div class="content">
            <form class="form-custom" (submit)="send()" >
                <div class="item item-input">
                    <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu Nome" />
                </div>    
                <div class="item item-input">
                    <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu email" />    
                </div> 
                <div class="item item-input">
                    <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Seu WhatsApp" mask="(99) 99999-9999" /> 
                </div>    
                <div class="item item-button">
                    <button> 
                        <i class="fa-solid fa-user"></i>
                        <span>Me cadastrar</span>
                    </button>
                </div>    
            </form> 
        </div>    
    </div>
</div>  
<loader *ngIf="loader"></loader>
