<div class="module-whatsapp" (click)="open()" *ngIf="template == null">
  <div class="content">
      <div class="header">
          <div class="icon-left">
              <i class="fa-solid fa-heart"></i>
          </div>    
          <span>Fale com <br>um consultor</span> 
      </div>
      <div class="icon">
          <i class="fa-brands fa-whatsapp"></i>
      </div> 
  </div> 
  <div class="content mobile">
      <div class="icon">
          <i class="fa-brands fa-whatsapp"></i>
      </div>  
  </div>   
</div>   
<div class="module-whatsapp-inline" (click)="open()" *ngIf="template == 'footer'">
    <div class="content-item">
        <h3>Fale por <br>WhatsApp</h3>
        <div class="contato-item">
            <div class="icon">  
                <i class="fa-brands fa-whatsapp"></i>
            </div>   
            <div class="content">
                <span>{{data.params.telefone}}</span>
            </div>
        </div>
    </div>
</div> 
<div class="module-whatsapp-inline" (click)="open()" *ngIf="template == 'page-contato'">
    <div class="card-item card-item-contact" (click)="open()">
        <div class="card-item-header">
            <div class="icon">
                <i class="fa-brands fa-whatsapp"></i>
            </div>
            <h3>{{data.params.telefone}}</h3>    
        </div>
    </div>  
</div> 
