import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDias'
})
export class FormatDiasPipe implements PipeTransform {


  constructor(){

  }
  /**
   * 
   * @param dias 
   * 
   * @param args 
   * @returns
   *  
   **/
  transform(dias:any,args:any):any {
    
    let result = "Todos os dias da semana.";
    let _e     = "e";


    if(dias.length < 7){

      let d = "";

      for (let index = 0; index < dias.length;index++) {
        
        if(index > 0 && (index+1) < dias.length){
          d += ", ";
        }
        if(dias.length == (index+1)){
          d += ` ${_e} `;
        }

        d += dias[index].descricao;
       
        
        
      }


      result = d+".";

    }

    return result;

  }

}
