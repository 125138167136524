<div class="menu-action-partial">
      <ul>
          <li>
            <btn-lista-desejo></btn-lista-desejo>    
          </li>  
          <li><div class="divisor"></div></li>
          <li>
            <btn-cart></btn-cart>   
          </li>
          <li><div class="divisor"></div></li>
          <li>
            <btn-usuario-login></btn-usuario-login>  
          </li>
      </ul>
  </div> 
    