<div class="module module-destination paralax" style='background-image: url("/images/destino/bg.jpg");'>
      <div class="module-title">
          <h2 class="wow slideInDown" data-wow-delay="0.4s">{{data.titulo}}</h2>
      </div>    
      <div class="module-content">
          <div class="container"> 
              <article class="description wow slideInUp margin-p" data-wow-delay="0.5s" [innerHtml]="data.params.texto"></article>
          </div>    
      </div> 
      <div class="bg"></div>   
</div>
<div class="frase">
      <div class="container">
          <div class="aspas wow slideInLeft" data-wow-delay="0.5s"><span>“</span></div>
          <p class="wow slideInRight" data-wow-delay="0.8s"><span>“</span>{{data.params.frase}}<span>”</span></p>
      </div>
</div>   
<div class="galeria">    
    <slide-carousel [data]="data.fotos"></slide-carousel>
</div>   
