<section class="page-home">  
  <modulo posicao="banner-slider" class="wow fadeIn"></modulo>
  <modulo posicao="produto-destaque-1"></modulo>
  <modulo posicao="destino"></modulo>
  <modulo posicao="blog"></modulo> 
  <modulo posicao="newsletter"></modulo>
  <modulo posicao="whatsapp"></modulo> 
</section>   

 



