<div class="content-bottom-main">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <modulo posicao="rede-social" template="footer"></modulo>   
            </div>
            <div class="col-md-4">
                <modulo posicao="contato" template="email"></modulo> 
            </div>
            <div class="col-md-4">  
                <modulo posicao="whatsapp" template="footer"></modulo> 
            </div>   
        </div>
    </div>    
</div>  
<footer>
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-item">
                    <h3 class="title-h3">Links Úteis</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/contato" title="Fale Conosco">
                                Entre em Contato 
                            </a>    
                        </li>
                        <li> 
                            <a routerLink="/sobre" title="Sobre a São Paulo Ecoturismo">
                                Sobre a São Paulo 
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/politicas-de-cancelamento" title="Políticas de Cancelamento">
                                Políticas de Cancelamento 
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/politicas-de-privacidade" title="Políticas de Privacidade">
                                Políticas de Privacidade
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/perguntas-frequentes" title="Perguntas Frequentes">
                                Perguntas Frequentes
                            </a> 
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-item">
                    <h3 class="title-h3">Minha conta</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/minha-conta/pedidos" title="Minhas Viagens">
                                Minhas Viagens
                            </a>    
                        </li>
                        <li>
                            <a routerLink="/carrinho" title="Meu Carrinho">
                                Meu Carrinho
                            </a>  
                        </li>
                        <li>
                            <a routerLink="/minha-conta/perfil" title="Meu Perfil">
                                Meu Perfil
                            </a> 
                        </li>
                    </ul>
                </div> 
                <div class="col-md-3 col-item"> 
                    <modulo posicao="horarios"></modulo>
                </div>
                <div class="col-md-3 col-item col-endereco"> 
                    <modulo posicao="endereco" template="footer"></modulo> 
                    <!--<h3 class="title-h3">Nosso endereço</h3>
                    <article>
                        <p>
                            Av Brasília, 108 Centro,Lençóis - Barreirinhas - CEP: 65590-000
                        </p>
                    </article>-->    
                </div>
            </div>
        </div>  
    </div>  
    <div class="bottom">
        <div class="container">
            <div class="copyright">
                <p>
                    <span>Copyright © 2022 - Todos os direitos reservados</span>
                    <a href="https://digitalmovement.com.br/" target="_blank" title="Digital Movement - Soluções em Tecnologia">
                        By <strong>Digital Movement</strong>
                        <img src="/images/logos/logo-dm.svg" alt="Digital Movement" width="20px" height="20px" />
                    </a>
                </p>
            </div> 
        </div>  
    </div> 
</footer>    