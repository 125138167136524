<div *ngFor="let m of modulos" [ngSwitch]="m.tag">
  <div *ngSwitchCase="'logo'">  
    <modulo-logo [data]="m" [classCustom]="classCustom" [template]="template"></modulo-logo>  
  </div>
  <div *ngSwitchCase="'whatsapp'">   
    <modulo-whatsapp [data]="m" [classCustom]="classCustom" [template]="template"></modulo-whatsapp>  
  </div>
  <div *ngSwitchCase="'rede-social'">   
    <modulo-rede-social [data]="m" [classCustom]="classCustom" [template]="template"></modulo-rede-social>  
  </div>
  <div *ngSwitchCase="'endereco'">     
    <modulo-endereco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-endereco>  
  </div>
  <div *ngSwitchCase="'banner-slider'"> 
    <banner-slider-slick [data]="m" [classCustom]="classCustom" [template]="template"></banner-slider-slick> 
  </div>
  <div *ngSwitchCase="'newsletter'"> 
    <modulo-newsletter [data]="m" [classCustom]="classCustom" [template]="template"></modulo-newsletter> 
  </div>
  <div *ngSwitchCase="'contato'">   
    <modulo-contato [data]="m" [classCustom]="classCustom" [template]="template"></modulo-contato> 
  </div> 
  <div *ngSwitchCase="'fale-conosco'">  
    <modulo-fale-conosco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-fale-conosco> 
  </div>
  <div *ngSwitchCase="'destinos'">       
    <modulo-destinos [data]="m" [template]="template" [classCustom]="classCustom"></modulo-destinos> 
  </div>
  <div *ngSwitchCase="'produto-destaque-1'">       
    <modulo-produto-destaque-one [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-destaque-one> 
  </div>
  <div *ngSwitchCase="'produto-destaque-2'">       
    <modulo-produto-destaque-two [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-destaque-two> 
  </div>
  <div *ngSwitchCase="'destino'">       
    <modulo-destino [data]="m" [template]="template"></modulo-destino> 
  </div>
  <div *ngSwitchCase="'blog'">       
    <modulo-blog [data]="m" [template]="template"></modulo-blog> 
  </div>
  <div *ngSwitchCase="'horarios'">        
    <modulo-horarios [data]="m" [template]="template"></modulo-horarios> 
  </div>
</div>
  
  
  